<template>
    <div class="user-edit-profile" v-if="translates && translates[langUrl]">
        <h3>{{ translates[langUrl].pageTitle[lang] }}</h3>
        <ValidationObserver>
            <ValidationProvider v-slot="{ errors }" rules="required|max:60">
                <div class="user-edit-profile__input-container">
                    <div class="user-edit-profile__input-container__input-name">{{ translates[langUrl].fieldName_firstname[lang] }}</div>
                    <BaseTextField 
                        :label="translates[langUrl].fieldName_firstname[lang]"
                        :value="userEdit.firstname"
                        :error-messages="errors[0]"
                        @input="setUserValue({ prop: 'firstname', value: $event })"
                    />
                </div>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" rules="required|max:60">
                <div class="user-edit-profile__input-container">
                    <div class="user-edit-profile__input-container__input-name">{{ translates[langUrl].fieldName_lastname[lang] }}</div>
                    <BaseTextField 
                        :label="translates[langUrl].fieldName_lastname[lang]"
                        :value="userEdit.lastname"
                        :error-messages="errors[0]"
                        @input="setUserValue({ prop: 'lastname', value: $event })"
                    />
                </div>
            </ValidationProvider>
            <div class="user-edit-profile__avatar-container">
                <div class="user-edit-profile__input-container__input-name">{{ translates[langUrl].fieldName_avatar[lang] }}</div>
                <div style="width:100%; display: flex; justify-content: center;">
                    <div
                        v-if="userEdit.avatar && userEdit.avatar !== ''"
                        :style="`background-image: url(${ imageSrc(userEdit.avatar) })`"
                        class="user-edit-profile__avatar__slot"
                    >
                        <div class="user-edit-profile__avatar__slot__picture-menu">
                            <v-menu 
                                bottom 
                                offset-y 
                                left 
                                offset-x>
                                <template #activator="{ on, attrs }">
                                    <img 
                                        src="../../assets/Group-menu.svg" 
                                        v-bind="attrs" 
                                        v-on="on" />
                                </template>
                                <v-list>
                                    <!-- <v-list-item @click="setTripValue({ prop_1: 'mainPictureIndex', value: index })">
                                        <v-list-item-title>Сделать главным</v-list-item-title>
                                    </v-list-item> -->
                                    <v-list-item @click="removeAvatar()">
                                        <v-list-item-title>{{ translates[langUrl].button_delete[lang] }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </div>
                    <ImgLoader 
                        v-if="!userEdit.avatar || userEdit.avatar === ''"
                        @change="setUserValue({ prop: 'avatar', value: $event })"
                    />
                </div>
            </div>
            <div class="user-edit-profile__input-container">
                <div class="user-edit-profile__input-container__input-name">{{ translates[langUrl].fieldName_birthday[lang] }}</div>
                <div style="width: 100%">
                    <BaseDatePicker 
                        :label="translates[langUrl].fieldName_birthday[lang]"
                        :value="userEdit.birthday"
                        @change="setUserValue({ prop: 'birthday', value: $event })"
                    />
                </div>
            </div>
            <div class="user-edit-profile__input-container">
                <div class="user-edit-profile__input-container__input-name">{{ translates[langUrl].fieldName_city[lang] }}</div>
                <BaseTextField
                    :label="translates[langUrl].fieldName_city[lang]"
                    :value="userEdit.city"
                    @input="setUserValue({ prop: 'city', value: $event })"
                />
            </div>
            <div class="user-edit-profile__input-container">
                <div class="user-edit-profile__input-container__input-name">{{ translates[langUrl].fieldName_phone[lang] }}</div>
                <BaseTextField
                    :label="translates[langUrl].fieldName_phone[lang]"
                    :value="userEdit.phone"
                    readonly
                />
            </div>
            <div class="user-edit-profile__input-container">
                <div class="user-edit-profile__input-container__input-name">{{ translates[langUrl].fieldName_email[lang] }}</div>
                <BaseTextField
                    :label="translates[langUrl].fieldName_email[lang]"
                    :value="userEdit.email"
                    @input="setUserValue({ prop: 'email', value: $event })"
                />
            </div>
            <div class="user-edit-profile__input-container">
                <div class="user-edit-profile__input-container__input-name">{{ translates[langUrl].fieldName_company[lang] }}</div>
                <BaseTextField
                    :label="translates[langUrl].fieldName_company[lang]"
                    :value="userEdit.company"
                    @input="setUserValue({ prop: 'company', value: $event })"
                />
            </div>
            <div class="user-edit-profile__input-container">
                <div class="user-edit-profile__input-container__input-name">{{ translates[langUrl].fieldName_site[lang] }}</div>
                <BaseTextField
                    :label="translates[langUrl].fieldName_site[lang]"
                    :value="userEdit.site"
                    @input="setUserValue({ prop: 'site', value: $event })"
                />
            </div>
            <!-- <div class="user-edit-profile__input-container">
                <div class="user-edit-profile__input-container__input-name">Instagram</div>
                <BaseTextField
                    label="Instagram"
                    :value="userEdit.instagram"
                    @input="setUserValue({ prop: 'instagram', value: $event })"
                />
            </div> -->
            <div class="user-edit-profile__input-container">
                <div class="user-edit-profile__input-container__input-name">{{ translates[langUrl].fieldName_social[lang] }}</div>
                <BaseTextField
                    :label="translates[langUrl].fieldName_social[lang]"
                    :value="userEdit.social"
                    readonly
                />
            </div>
            <div class="user-edit-profile__input-container">
                <div class="user-edit-profile__input-container__input-name">{{ translates[langUrl].fieldName_about[lang] }}</div>
                <BaseTextArea 
                    :label="translates[langUrl].fieldName_about[lang]"
                    :value="userEdit.about"
                    @input="setUserValue({ prop: 'about', value: $event })"
                />
            </div>
            <div class="user-edit-profile__input-container">
                <div class="user-edit-profile__input-container__input-name">{{ translates[langUrl].fieldName_reviews[lang] }}</div>
                <BaseTextField
                    :label="translates[langUrl].fieldName_reviews[lang]"
                    :value="userEdit.reviews"
                    @input="setUserValue({ prop: 'reviews', value: $event })"
                />
            </div>
            <div class="user-edit-profile__input-container">
                <div class="user-edit-profile__input-container__input-name">{{ translates[langUrl].images[lang] }}</div>
                <div class="user-edit-profile__img-container">
                    <template v-for="(picture, index) in userEdit.images">  
                        <div
                            :key="index"
                            :style="`background-image: url(${ imageSrc(picture) })`"
                            class="img_slot"
                        >
                            <div class="img_slot__picture-menu">
                                <v-menu 
                                    bottom 
                                    offset-y 
                                    left 
                                    offset-x>
                                    <template #activator="{ on, attrs }">
                                        <img 
                                            src="../../assets/Group-menu.svg" 
                                            v-bind="attrs" 
                                            v-on="on" />
                                    </template>
                                    <v-list>
                                        <!-- <v-list-item @click="setTripValue({ prop_1: 'mainPictureIndex', value: index })">
                                    <v-list-item-title>Сделать главным</v-list-item-title>
                                </v-list-item> -->
                                        <v-list-item @click="removePicture({ prop: 'images', value: index })">
                                            <v-list-item-title>{{ translates[langUrl].button_delete[lang] }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <ImgLoader 
                :small="userEdit.images && userEdit.images.length > 0"
                @change="setTripPicture({ prop: 'images', value: $event })"
            />
            <BaseButton
                @click="save"
            >{{ translates[langUrl].button_save[lang] }}</BaseButton>
        </ValidationObserver>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import store from '@/store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { imageSrc, checkUserProfile } from '@/helpers';
    import { TRIP_STATUS } from '@/vars';

    import BaseTextField from '../../components/common/BaseTextField.vue'
    import BaseTextArea from '../../components/common/BaseTextArea.vue'
    import BaseButton from '../../components/common/BaseButton.vue'
    import BaseDatePicker from '../../components/common/BaseDatePicker.vue'
    import ImgLoader from '../../components/common/ImgLoader.vue'

    export default {
        name: 'ProfileEdit',
        metaInfo() {
            return {
                title: this.translates[this.langUrl]? this.translates[this.langUrl].browserTitle_edit[this.lang] : ''
            }
        },
        components: {
            BaseTextField,
            BaseTextArea,
            BaseButton,
            BaseDatePicker,
            ImgLoader,
            ValidationProvider,
            ValidationObserver
        },
        data: () => ({
            imageSrc,
            deletedPictures: [],
            userEdit: {},
            langUrl: '/user/profile'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', {
                user: state => state.user
            }),
            ...mapState('translates', {
                translates: state => state.entities
            }),
        },
        async mounted() {
            this.$root.$emit('preloaderShow');
            await store.dispatch('auth/fetch');
            this.userEdit = JSON.parse(JSON.stringify(this.user));
            this.$root.$emit('preloaderHide');
        },
        methods: {
            setUserValue({ prop, value }) {
                this.userEdit[prop] = value;
                //store.commit('auth/SET_VALUE', { prop, value });
            },
            async save() {
                store.commit('auth/SET_USER', this.userEdit);
                await store.dispatch('auth/save');
                await store.dispatch('auth/fetch');

                if(checkUserProfile(this.user)) {
                    const trips = await store.dispatch('trips/fetch', { filter: { 
                        user: this.user._id,
                        status: TRIP_STATUS.NEED_USER
                    } });
                    for(const trip of trips.items) {
                        await store.dispatch('trips/setStatus', { id: trip._id, status: TRIP_STATUS.DRAFT });
                    }
                } else {
                    const trips = await store.dispatch('trips/fetch', { filter: { 
                        user: this.user._id,
                        status: TRIP_STATUS.DRAFT
                    } });
                    for(const trip of trips.items) {
                        await store.dispatch('trips/setStatus', { id: trip._id, status: TRIP_STATUS.NEED_USER });
                    }
                }
                await this.$router.push({ name: 'user-profile' });
            },
            removeAvatar() {
                this.userEdit.avatar = '';
                // store.commit('auth/SET_VALUE', { prop: 'avatar', value: '' });
            },
            setTripPicture({ prop, value }) {
                let pictures = this.userEdit[prop];
                pictures.push(value);
                // store.commit('auth/SET_VALUE', { prop, value: pictures });
            },
            removePicture({ prop, value }) {
                let pictures = this.userEdit[prop];
                pictures.splice(value, 1);
                // store.commit('auth/SET_VALUE', { prop, value: pictures });
            },
        }
    }
</script>

<style lang="scss">

.user-edit-profile {
    padding: 20px;
    justify-content: center;
    &__avatar-container {
        display: flex;
        // justify-content: space-between;
    }
    &__avatar__slot {
        width: 150px;
        height: 150px;
        background-size: cover;
        background-position: center;
        border-radius: 12px;
        position: relative;
        margin: 15px;
        &__picture-menu {
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
    &__img-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            justify-content: space-between;
            @media all and (min-width: 768px) {
                    justify-content: flex-start;
                }
        }
    .img_slot {
        position: relative;
        overflow: hidden; 
        margin-bottom: 12px;
        width: 160px;
        height: 99px;
        background-size: cover;
        background-position: center;
        @media all and (min-width: 768px) {
                width: 175px;
                height: 166px;
                margin-right: 16px;
                &:nth-child(6n) {
                    margin-right: 0;
                }
            }
        border-radius: 10px;
        &__picture-menu {
            position: absolute;
            top: 8px;
            right: 8px;
        }
    }
    &__input-container{
        display: flex;
        min-height: 56px;
        width: 100%;
        &__input-name{
            display: none;
            @media all and (min-width: 768px) {
                display: flex;
                min-width: 185px;
                max-width: 185px;
                margin-right: 40px;
                color: #65676E;
                padding-top: 18px;
            }
        }
    }
}
</style>